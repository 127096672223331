import './../../../assets/style.css';
import React from 'react';
import {Context} from './../../../../App/Components/Context';
import Loading from './../../../../App/Components/Templates/Loading';
import Api from './../../../../App/Components/Utils/Api';
import UI from './../../../../App/Components/Utils/UI';
import {baseUrl} from './../../../../../config';
import $ from "jquery";

export default class Withdrawal extends React.Component {
    constructor(props) {
        super(props);
        Withdrawal.contextType = Context;
        this.state = {
            amount: 0,
            pin: '',
            pin_confirmation: '',
            submitting: false,
            message: '',
            tab: 'withdrawal',
            error: {
                amount: [],
                pin: [],
                pin_confirmation: []
            }
        }
    }

    componentDidMount() {
        if (this.context && this.context.user && !this.context.user.has_transaction_pin){
            this.setState({tab:'pin'})
        }
    }

    close() {
        this.context.updateContext('withdrawalModalType', undefined);
        this.context.updateContext('withdrawalModalData', undefined);
        $('#withdrawal-modal').modal('hide');
    }

    submit() {
        let error = this.state.error;
        for (let i in error) {
            if (error.hasOwnProperty(i)) {
                error[i] = [];
            }
        }
        this.setState({message: ''});

        if (parseInt (this.state.amount) && parseInt (this.state.amount) <= 0) {
            error.amount.push('Please provide transaction amount');
        }

        if (this.state.pin.trim() === '') {
            error.pin.push('Please provide the transaction pin');
        }

        this.setState({error: error});

        for (let i in error) {
            if (error.hasOwnProperty(i)) {
                if (error[i].length) {
                    return false;
                }
            }
        }

        this.setState({submitting: true});
        Api.fetch('payment.withdrawal.request', undefined, {
            amount: this.state.amount,
            pin: this.state.pin,
        }).then(({status, responseJSON}) => {
            this.setState({submitting: false});
            if (status === 200) {
                this.setState({amount: '', pin: ''}, () => {
                    this.close();
                    UI.alert({
                        title: 'Withdrawal Request',
                        content: 'Your withdrawal request has been sent successfully',
                    }, this.context);
                });
            } else {
                this.setState({message: responseJSON.message || ''});
            }
        }).catch((reason) => {
            this.setState({submitting: false});
            let {status, responseJSON} = typeof reason === 'object' ? reason : {};
            if ([403, 422].indexOf(status) !== -1) {
                this.setState({error: {...error, ...responseJSON.error}, message: responseJSON.message || ''});
            } else {
                console.debug(reason);
                this.setState({message: (responseJSON && responseJSON.message) || 'Error Occurred! Please check the internet and try again'});
            }
        });
    }
    submitPin() {
        let error = this.state.error;
        for (let i in error) {
            if (error.hasOwnProperty(i)) {
                error[i] = [];
            }
        }
        this.setState({message: ''});

        if (typeof this.state.pin !== 'string' || this.state.pin.trim() === '') {
            error.pin.push('Please provide transaction pin');
        }

        if (typeof this.state.pin_confirmation !== 'string' || this.state.pin_confirmation.trim() === '') {
            error.pin_confirmation.push('Please confirm the transaction pin');
        }

        this.setState({error: error});

        for (let i in error) {
            if (error.hasOwnProperty(i)) {
                if (error[i].length) {
                    return false;
                }
            }
        }

        this.setState({submitting: true});
        Api.fetch('payment.transaction.pin', undefined, {
            pin_confirmation: this.state.pin_confirmation,
            pin: this.state.pin,
        }).then(({status, responseJSON}) => {
            this.setState({submitting: false});
            if (status === 200) {
                this.setState({pin_confirmation: '', pin: '',tab:'withdrawal'}, () => {
                    UI.alert({
                        title: 'Transaction Pin',
                        content: 'Your transaction pin has been saved successfully',
                    }, this.context);
                });
            } else {
                this.setState({message: responseJSON.message || ''});
            }
        }).catch((reason) => {
            this.setState({submitting: false});
            let {status, responseJSON} = typeof reason === 'object' ? reason : {};
            if ([403, 422].indexOf(status) !== -1) {
                this.setState({error: {...error, ...responseJSON.error}, message: responseJSON.message || ''});
            } else {
                console.debug(reason);
                this.setState({message: (responseJSON && responseJSON.message) || 'Error Occurred! Please check the internet and try again'});
            }
        });
    }

    render() {
        return (
            <div id="withdrawal-modal" className="modal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button type="button" className="close-button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        {this.state.tab === 'withdrawal'?(
                            <form id="withdrawal-modal-form" className="investment" onSubmit={(e) => {
                                e.preventDefault();
                                this.submit();
                            }}>
                                {this.state.submitting && <Loading/>}
                                <div className="heading">
                                    {!this.context.withdrawalModalType ? 'Withdrawal Request' : ''}
                                </div>
                                <div className="form-groups">
                                    <div className="form-group">
                                        <div className="amount">
                                            <input className="form-control input" id="withdrawal-amount" type="number"
                                                   name="amount" placeholder="Enter amount" onChange={(e) => {
                                                this.setState({amount: e.target.value})
                                            }}/>
                                            <i className="icon las la-naira primary"/>
                                        </div>
                                        <div className="error"
                                             dangerouslySetInnerHTML={{__html: this.state.error.amount.join('<br />')}}/>
                                    </div>
                                    <div className="form-group">
                                        <div className="amount">
                                            <input className="form-control input" id="withdrawal-pin" type="password" name="pin"
                                                   placeholder="Enter transaction pin" onChange={(e) => {
                                                this.setState({pin: e.target.value})
                                            }}/>
                                        </div>
                                        <div className="error"
                                             dangerouslySetInnerHTML={{__html: this.state.error.pin.join('<br />')}}/>
                                    </div>
                                </div>
                                <div className="submit">
                                    <div className="message">{this.state.message}</div>
                                    <button type="submit" className="btn btn-primary">Request</button>
                                </div>
                            </form>
                        ):null}
                        {this.state.tab === 'pin'?(
                            <form id="withdrawal-modal-form" className="investment" onSubmit={(e) => {
                                e.preventDefault();
                                this.submitPin();
                            }}>
                                {this.state.submitting && <Loading/>}
                                <div className="heading">
                                    Set Withdrawal Pin
                                </div>
                                <div className="form-groups">
                                    <div className="form-group">
                                        <div className="amount">
                                            <input className="form-control input" id="pin-t" type="password"
                                                   name="amount" placeholder="Enter transaction Pin" onChange={(e) => {
                                                this.setState({pin: e.target.value})
                                            }}/>
                                        </div>
                                        <div className="error"
                                             dangerouslySetInnerHTML={{__html: this.state.error.pin.join('<br />')}}/>
                                    </div>
                                    <div className="form-group">
                                        <div className="amount">
                                            <input className="form-control input" id="withdrawal-pin" type="password" name="pin"
                                                   placeholder="Confirm transaction pin" onChange={(e) => {
                                                this.setState({pin_confirmation: e.target.value})
                                            }}/>
                                        </div>
                                        <div className="error"
                                             dangerouslySetInnerHTML={{__html: this.state.error.pin_confirmation.join('<br />')}}/>
                                    </div>
                                </div>
                                <div className="submit">
                                    <div className="message">{this.state.message}</div>
                                    <button type="submit" className="btn btn-primary">Set Pin</button>
                                </div>
                            </form>
                        ):null}
                    </div>
                </div>
            </div>
        );
    }
}
